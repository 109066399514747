import React from "react";
import "./TeamCard.css";

const teamMembers = {
  management: [
    {
      name: "Selina Loum",
      role: "President",
      imgSrc: "/assets/img/team/Selina_Loum.jpg",
    },
    {
      name: "Scott Dyer",
      role: "Sales Director",
      imgSrc: "/assets/img/team/scott.jpg",
    },
    {
      name: "Erman Ozkan",
      role: "Business Development",
      imgSrc: "/assets/img/team/erman (2).jpg",
    },
    {
      name: "Michael huffman",
      role: "Account Manager",
      imgSrc: "/assets/img/team/michael_huffman.jpg",
    },
    {
      name: "Paula Suarez",
      role: "Office & Claims Admin",
      imgSrc: "/assets/img/team/paula.jpg",
    },
    {
      name: "Jordan Gallo",
      role: "Client Services",
      imgSrc: "/assets/img/team/Jordan_Gallo.jpg",
    },
    {
      name: "Kris Fernandez",
      role: "Account Manager",
      imgSrc: "/assets/img/team/avatar_male.png",
    },
    {
      name: "Terrilyn Blatney",
      role: "Business Development",
      imgSrc: "/assets/img/team/avatar_female.png",
    },
    {
      name: "Judi Kwong",
      role: "Business Development",
      imgSrc: "/assets/img/team/avatar_female.png",
    },
  ],
};

const TeamCard = () => {
  return (
    <div className="teamCard-container">
      <div className="team-title-box">
        <h2>MANAGEMENT</h2>
      </div>

      <div className="teamPerson-Container">
        {teamMembers.management.map((member, index) => (
          <div className="teamPerson-box" key={index}>
            <img src={member.imgSrc} alt={member.name} />
            <div className="teamPerson-box-overlay">
              <h3>{member.name}</h3>
              <span>{member.role}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamCard;
