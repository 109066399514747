import React, { useState } from "react";
import { Modal, Toast } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { Icon } from "@iconify/react";
import "./contactform.css";
import parseError from "../../../utils/ErrorParse";
import Loader from "../../Loader/Loader";

const Contactform = () => {
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const apiBaseUrl = "https://extended-trust-nodejs.onrender.com/";
  // const apiBaseUrl = "http://192.168.1.13:3000/";

  const [showmodal, setShowmodal] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const validationForm = () => {
    let errorObj = {
      name: false,
      email: false,
      phone: false,
      message: false,
    };

    if (inputValue.name === "" || inputValue === undefined) {
      setFormErrors((prevData) => ({
        ...prevData,
        name: true,
      }));
      errorObj.name = true;
    } else if (inputValue.email === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        email: true,
      }));
      errorObj.email = true;
    } else if (inputValue.phone === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        phone: true,
      }));
      errorObj.phone = true;
    } else if (inputValue.message === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        message: true,
      }));
      errorObj.message = true;
    }

    return errorObj;
  };

  const handleSubmit = async () => {
    try {
      if (submitLoader) return;
      const valid = validationForm();
      setSubmitLoader(true);
      if (valid.name || valid.email || valid.phone || valid.message) {
        console.log("missing fields");
        return;
      }
      const response = await axios.post(
        `${apiBaseUrl}contact-form`,
        inputValue,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        toast.success("Request Has Been Sent!!");
        setInputValue({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      console.log(error);
      const errorMessage = parseError(error);
      toast.error(errorMessage?.message ?? "Something went wrong!");
    }
  };
  const createAutoReply = async (event, userEmail) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `https://extended-trust-nodejs-d43e1844c232.herokuapp.com/admin/auto-reply`,
        { email: userEmail }
      );

      console.log(response.data);
      // if (response.data) {
      //   toast.success("Staff Added Successfully");
      // }
    } catch (error) {
      // if (error.response) {
      toast.error(error);
      // }
      //   toast.error(error);
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    setInputValue((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  return (
    <div>
      <Modal show={showmodal} className="confirmmodal">
        <Modal.Body>
          <Icon
            icon="mdi:tick-circle"
            width="60"
            height="60"
            style={{ color: "#4A6543" }}
          />
          <h4>Thank You!</h4>
          <p>Your message has been submitted successfully.</p>
          <button onClick={() => setShowmodal(false)}>OK</button>
        </Modal.Body>
      </Modal>
      <div className="contactform-send d-flex pb-60 mt-5">
        <div className="contactform-left-container">
          <div className="contactform-SendmsgText">
            <h2>
              Send Us a {""}
              <span>Message</span>
            </h2>
          </div>
          <p>
            Connect with ease drop us a message, and let's start resolving your
            queries promptly
          </p>

          <section id="contact-form">
            <div class="form-field">
              <label class="label" for="msg-name">
                Full Name <span class="required">*</span>
              </label>
              <input
                type="text"
                name="name"
                class="input jsvalidate"
                id="msg-name"
                value={inputValue.name}
                onChange={handleInputChange}
                required={true}
              />
              {formErrors.name && (
                <span className="ErrorMessage">FullName is required</span>
              )}
            </div>
            <div class="form-field">
              <label class="label" for="msg-email">
                Email Address <span class="required">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="msg-email"
                class="input jsvalidate"
                value={inputValue.email}
                onChange={handleInputChange}
                required
              />
              {formErrors.email && (
                <span className="ErrorMessage">Email is required</span>
              )}
            </div>
            <div class="form-field">
              <label class="label" for="msg-phone">
                Phone Number <span class="required">*</span>
              </label>
              <input
                maxLength={13}
                type="tel"
                name="phone"
                id="msg-phone"
                className={`input jsvalidate ${
                  !isValidPhoneNumber ? "invalid" : ""
                }`}
                value={inputValue.phone}
                onChange={(event) => {
                  const { name, value } = event.target;
                  const formattedValue = value.startsWith("+1")
                    ? value
                    : `+1 ${value}`;
                  if (name === "phone") {
                    const phonePattern =
                      /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                    const isValidPhoneNumber =
                      phonePattern.test(formattedValue);
                    setIsValidPhoneNumber(isValidPhoneNumber);
                  }
                  setInputValue((prev) => ({
                    ...prev,
                    [name]: formattedValue,
                  }));
                  setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: false,
                  }));
                }}
                required
              />
              {formErrors.phone && (
                <span className="ErrorMessage">Phone Number is required</span>
              )}
            </div>
            <div class="form-field">
              <label class="label" for="msg-comments">
                Message <span class="required">*</span>
              </label>
              <textarea
                name="message"
                id="msg-comments"
                class="textarea jsvalidate"
                value={inputValue.message}
                onChange={handleInputChange}
                required
              ></textarea>
              {formErrors.message && (
                <span className="ErrorMessage">Message is required</span>
              )}
            </div>
            <div class="form-buttons right">
              <button
                disabled={submitLoader}
                type="submit"
                name="submitform"
                class={`button submit theme-btn-1 btn btn-effect-1 d-flex justify-content-between align-items-center`}
                style={
                  submitLoader ? { pointerEvents: "none", opacity: "0.5" } : {}
                }
                onClick={handleSubmit}
              >
                <span>SEND MESSAGE</span>
                {submitLoader && (
                  <Loader cssClass={"text-black"} isSmallView={true} />
                )}
              </button>
            </div>
          </section>
        </div>
        <div className="contactform-right-container">
          <img
            src="/assets/img/selfmade/Images/contact-side.png"
            alt=""
            style={{ maxHeight: "900px", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contactform;
